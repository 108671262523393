import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { TemplateWizard } from 'components/TemplateWizard/TemplateWizard';
import { fetchIncidentStatus } from 'actions/profileActions';
import { startLoading } from 'reducers/loading/loading.action';
import { 
  fetchTeamTemplates, 
  generateTeamsSite,
  checkTeamsStatus,
} from 'actions/teamsActions';
import { IconContainerWithButton } from 'components/DesignSystems/IconContainerWithButton/IconContainerWithButton';
import { ExistingTemplates } from 'components/TemplateWizard/ExistingTemplates';
import { StepComponent } from '../../types/wizard';

const GenerateTeamSite = () => {

  const [checkTeamStatusRequested, setCheckTeamStatusRequested] = useState(false)

  const [
    showSelectTeamsTemplateDialog,
    setShowSelectTeamsTemplateDialog,
  ] = useState(false);

  const currentIncident = useSelector((state: any) => {
    return state.app.currentIncident;
  });
  
  const teamsTemplates = useSelector((state: any) => {
    return state.app.teamsTemplates;
  });

  const tenantId = useSelector((state: any) => {
    return state.app.tenantId;
  });

  useEffect(() => {

    if (currentIncident && !checkTeamStatusRequested) {
      reduxDispatch(checkTeamsStatus(currentIncident.id));
      setCheckTeamStatusRequested(true)
    }
  }, [currentIncident])


  const onClickGenerateTeamsSite = async (incident_id:any) => {
    await reduxDispatch(fetchIncidentStatus(incident_id));

    if (currentIncident?.team_status === 'unstarted') {
      setShowSelectTeamsTemplateDialog(true);
      if (teamsTemplates.length == 0) {
        reduxDispatch(startLoading());
        reduxDispatch(fetchTeamTemplates(tenantId));
      }
    }
  };

  const templateSelectionClicked = (template_id:any) => {
    reduxDispatch(
      generateTeamsSite(currentIncident?.id, template_id, tenantId)
    );
    setShowSelectTeamsTemplateDialog(false);
  };

  const reduxDispatch = useDispatch()

  const StepOneComponent: StepComponent = ({ handleTemplateSelect }) => (
    <IconContainerWithButton
      buttonText="Use Existing"
      description="Implement existing Teams templates to expedite setup, leveraging established structures for quick operational integration."
      title="Use Existing Templates"
      onButtonClick={() => handleTemplateSelect()}
      icon="file_copy"
    />
  );

  const StepTwoComponent: StepComponent = ({ handleTemplateSelect }) => (
    <ExistingTemplates handleTemplateSelect={handleTemplateSelect} />
  );

  const StepThreeComponent: StepComponent = ({ selectedTemplate }) => (
    <div className="w-100 d-flex flex-column align-items-start justify-content-between">
      <div className="w-100 d-flex flex-row justify-content-between">
        <span className="label">Template Name</span>
        <span>{selectedTemplate?.label}</span>
      </div>
    </div>
  );

  return (
    <>
     {!!tenantId && (
          <>
            {(!!currentIncident &&
              !currentIncident?.teams_id &&
              !currentIncident?.channel_id &&
              !currentIncident?.sharepoint_location && 
              currentIncident?.team_status === "unstarted" && (
                <>
                  <StylishNewButton
                    className="button--secondary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0"
                    onClick={() =>
                      onClickGenerateTeamsSite(
                        currentIncident?.id
                      )
                    }
                  >
                    Generate Teams Site
                  </StylishNewButton>
                  {showSelectTeamsTemplateDialog && (
                    <TemplateWizard
                      steps={[
                        {
                          id: 1,
                          label: 'Select Starting Point',
                          component: StepOneComponent,
                          pageTitle: 'Select Starting Point',
                          pageSubTitle:
                            'You can start from scratch or build upon a pre-designed template.',
                        },
                        {
                          id: 2,
                          label: 'Choose a template',
                          pageTitle:
                            'Choose from Existing Templates',
                          pageSubTitle:
                            'Select from existing Teams or PRATUS templates to get started',
                          component: StepTwoComponent,
                        },
                        {
                          id: 3,
                          label: 'Launch',
                          pageTitle: 'Launch Your Template',
                          component: StepThreeComponent,
                        },
                      ]}
                      breadcrumbs={[
                        {
                          id: 1,
                          label: 'Select Starting Point',
                          isActive: false,
                        },
                        {
                          id: 2,
                          label: 'Use Existing Templates',
                          isActive: true,
                        },
                      ]}
                      onSubmit={(template) => {
                        templateSelectionClicked(
                          template.value
                        );
                      }}
                      onModalClose={() =>
                        setShowSelectTeamsTemplateDialog(false)
                      }
                    />
                  )}
                </>
              )) ||
              (currentIncident?.team_status ===
                'inprogress' && (
                <div style={{ marginLeft: '16px' }}>
                  Creating Teams Workspace...
                </div>
              )) ||
              (currentIncident?.team_status === 'done' && (
                <div style={{ marginLeft: '16px' }}>
                  Teams Workspace Created!
                </div>
              ))}
          </>
         )} 
       </>
    )
};
export default GenerateTeamSite;