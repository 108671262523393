import React, { useState } from 'react';
import { I18n, useTranslate } from 'react-polyglot';
import { Navigate, NavLink, Outlet, Route, Routes } from 'react-router-dom';
import OrgProfile from './OrgProfile';
import OrgMembers from './OrgMembers';
import OrgTeams from './OrgTeams';
import ManageLocation from './ManageLocation';
import GroupRoles from 'components/Roles/GroupRoles';
import GroupIncidents from './GroupIncidents';
import GroupWorkspaceUpdated from './GroupWorkspaceUpdated';
import GroupAIUsage from './GroupAIUsage';
import PageTitle from '../headers/PageTitle';
import RouteError from '../Error/RouteError';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import NewOrganizationModal from './NewOrganizationModal';
import ICS from 'components/ICS/ICS';
import { useStreamlinePlatformFlag } from '../../utils/featureFlags';

const Wrapper: React.FC = () => {
  const messages = {
    ORGANIZATION_SETTINGS: 'Organization Settings',
    ORG_PROFILE: 'Organization Profile',
  };
  return (
    <I18n locale="en" messages={messages}>
      <Routes>
        <Route element={<OrganizationSettings />} errorElement={<RouteError />}>
          <Route index element={<Navigate to="profile" />} />
          <Route path="/profile" element={<OrgProfile />} />
          <Route path="/members" element={<OrgMembers />} />
          <Route path="/teams" element={<OrgTeams />} />
          <Route path="/aor" element={<ManageLocation />} />
          <Route path="/roles" element={<GroupRoles />} />
          <Route path="/incidents" element={<GroupIncidents />} />
          <Route path="/exercises" element={<GroupWorkspaceUpdated />} />
          <Route
            path="/aiusage"
            element={<GroupAIUsage group_guid={null} group_name={null} />}
          />
          <Route path="/ics" element={<ICS reduxCurrentIncident={null} />} />
          <Route path="*" element={<OrgProfile />} />
        </Route>
      </Routes>
    </I18n>
  );
};

const OrganizationSettings: React.FC = () => {
  const t = useTranslate();

  const streamlinePlatform = useStreamlinePlatformFlag();

  const [showNewOrganizationModal, setShowNewOrganizationModal] = useState(
    false
  );

  return (
    <>
      <div className="p-4 pt-0">
        <PageTitle title={t('ORGANIZATION_SETTINGS')}>
          <StylishNewButton
            className="button--secondary w-100 w-md-auto"
            onClick={() => setShowNewOrganizationModal(true)}
          >
            Create Organization
          </StylishNewButton>
        </PageTitle>
        <div className="d-flex p-3 secondary-nav">
          <div className="secondary-left-nav">
            <ul>
              <li>
                <NavLink to="/groups/profile" className="tab">
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink to="/groups/members" className="tab">
                  Members
                </NavLink>
              </li>
              <li>
                <NavLink to="/groups/roles" className="tab">
                  Roles and Positions
                </NavLink>
              </li>
              <li>
                <NavLink to="/groups/teams" className="tab">
                  Teams
                </NavLink>
              </li>
              <li>
                <NavLink to="/groups/aor" className="tab">
                  Areas of Responsibility
                </NavLink>
              </li>
              <li>
                <NavLink to="/groups/incidents" className="tab">
                  Incidents
                </NavLink>
              </li>
              <li>
                <NavLink to="/groups/exercises" className="tab">
                  Exercises
                </NavLink>
              </li>
              <li>
                <NavLink to="/groups/aiusage" className="tab">
                  AI Usage
                </NavLink>
              </li>
              {!streamlinePlatform && (
                <li>
                  <NavLink to="/groups/ics" className="tab">
                    ICS
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
          <div className="secondary-right-content pe-0">
            <Outlet />
          </div>
        </div>
      </div>
      {showNewOrganizationModal && (
        <NewOrganizationModal
          show={true}
          onClose={() => setShowNewOrganizationModal(false)}
        />
      )}
    </>
  );
};

export default Wrapper;
